import React, { Component } from "react";
import ReactPlayer from "react-player";
import Collapse from "@kunukn/react-collapse";
import classes from "./Dashboard.css";
import { Col } from "react-bootstrap";
import bgMenu from "../assets/videos/mobiel_bg_menu.mp4";
import bgLooking from "../assets/videos/mobiel_bg_looking.mp4";
import bgWhatYouDo from "../assets/videos/bg_what_youll_do.mp4";
import bgCharacteristics from "../assets/videos/bg_personal_characteristics.mp4";
import bgRequirements from "../assets/videos/bg_requirements.mp4";
import bgBenefits from "../assets/videos/bg_benefits_perks_and_compensation.mp4";

class Dashboard extends Component {
  state = {
    index: null,
    loading: true,
    playingVideo: null
  };

  onToggle = index => {
    this.setState(state => ({ index: state.index === index ? null : index }));
  };
  trailerEnded = () => {
    this.setState({ loading: false });
  };
  videoHandler = index => {
    setTimeout(() => {
      let vid = null;
      if (this.state.index !== null) {
        switch (this.state.index) {
          case 0:
            vid = (
              <video
                className={classes.videoMenu}
                src={bgLooking}
                allowFullScreen
                autoPlay
                loop
              />
            );

            this.setState({ playingVideo: vid });
            break;
          case 1:
            vid = (
              <video
                className={classes.videoMenu}
                src={bgLooking}
                allowFullScreen
                autoPlay
                loop
              />
            );
            this.setState({ playingVideo: vid });
            break;
          case 2:
            vid = (
              <video
                className={classes.videoMenu}
                src={bgWhatYouDo}
                allowFullScreen
                autoPlay
                loop
              />
            );
            this.setState({ playingVideo: vid });
            break;
          case 3:
            vid = (
              <video
                className={classes.videoMenu}
                src={bgCharacteristics}
                allowFullScreen
                autoPlay
                loop
              />
            );
            this.setState({ playingVideo: vid });
            break;
          case 4:
            vid = (
              <video
                className={classes.videoMenu}
                src={bgRequirements}
                allowFullScreen
                autoPlay
                loop
              />
            );
            this.setState({ playingVideo: vid });
            break;
          case 5:
            vid = (
              <video
                className={classes.videoMenu}
                src={bgBenefits}
                allowFullScreen
                autoPlay
                loop
              />
            );
            this.setState({ playingVideo: vid });
            break;
          default:
            console.log("default case");
            break;
        }
      } else {
        vid = this.state.playingVideo;
        this.setState({ playingVideo: vid });
      }
    }, 100);
  };

  render() {
    let backgroundVideo = (
      <div>
        <video
          className={classes.videoMenu}
          src={bgMenu}
          allowFullScreen
          autoPlay
          loop
        />
      </div>
    );
    console.log(this.state.index);
    return this.state.loading === true ? (
      <div className={classes.Dashboard}>
        <div>
          <ReactPlayer
            className={classes.videoTrailer}
            url="https://player.vimeo.com/video/328412396"
            playing
            width="100%"
            height="100%"
            onEnded={this.trailerEnded}
          />
        </div>
      </div>
    ) : (
      <div className={classes.Dashboard}>
        {backgroundVideo}
        <div
          className={
            this.state.index ? classes.videoFadeIn : classes.videoFadeOut
          }
        >
          {this.state.playingVideo}
        </div>
        <Col className={classes.nav} sm={7}>
          <button
            className={classes.btn}
            onClick={() => {
              this.onToggle(1);
              this.videoHandler(this.state.index);
            }}
          >
            <span>We are looking for</span>
          </button>
          <Collapse
            className={classes.Collapse}
            isOpen={this.state.index === 1}
            onChange={({ collapseState }) => {
              this.setState({ item1: collapseState });
            }}
            onInit={({ collapseState }) => {
              this.setState({ item1: collapseState });
            }}
            render={collapseState => createItem1(collapseState)}
          />

          <button
            className={classes.btn}
            onClick={() => {
              this.onToggle(2);
              this.videoHandler(this.state.index);
            }}
          >
            <span>What you'll do</span>
          </button>
          <Collapse
            className={classes.Collapse}
            isOpen={this.state.index === 2}
            onChange={({ collapseState }) => {
              this.setState({ item2: collapseState });
            }}
            onInit={({ collapseState }) => {
              this.setState({ item2: collapseState });
            }}
            render={collapseState => createItem2(collapseState)}
          />

          <button
            className={classes.btn}
            onClick={() => {
              this.onToggle(3);
              this.videoHandler(this.state.index);
            }}
          >
            <span>Personal characteristics</span>
          </button>
          <Collapse
            className={classes.Collapse}
            isOpen={this.state.index === 3}
            onChange={({ collapseState }) => {
              this.setState({ item3: collapseState });
            }}
            onInit={({ collapseState }) => {
              this.setState({ item3: collapseState });
            }}
            render={collapseState => createItem3(collapseState)}
          />

          <button
            className={classes.btn}
            onClick={() => {
              this.onToggle(4);
              this.videoHandler(this.state.index);
            }}
          >
            <span>Requirements</span>
          </button>
          <Collapse
            className={classes.Collapse}
            isOpen={this.state.index === 4}
            onChange={({ collapseState }) => {
              this.setState({ item4: collapseState });
            }}
            onInit={({ collapseState }) => {
              this.setState({ item4: collapseState });
            }}
            render={collapseState => createItem4(collapseState)}
          />
          <button
            className={classes.btn}
            onClick={() => {
              this.onToggle(5);
              this.videoHandler(this.state.index);
            }}
          >
            <span>
              Benefits, perks and <br />
              compensations
            </span>
          </button>
          <Collapse
            className={classes.Collapse}
            isOpen={this.state.index === 5}
            onChange={({ collapseState }) => {
              this.setState({ item5: collapseState });
            }}
            onInit={({ collapseState }) => {
              this.setState({ item5: collapseState });
            }}
            render={collapseState => createItem5(collapseState)}
          />
        </Col>
        <footer>
          <Col className={classes.columnFooter} sm={6}>
            <button className={classes.interestedBtn}>
              <a
                href="https://werkenbij.yunify.nl/nl/ad/motion-graphics-artist/1qx72j"
                target="_blank"
                rel="noopener noreferrer"
              >
                INTERESTED?
              </a>
            </button>
            <h2>Life is short,</h2>
            <span>work somewhere awesome!</span>
          </Col>
        </footer>
      </div>
    );
  }
}

export default Dashboard;

const createItem1 = collapseState => {
  return (
    <div className={classes.Content}>
      <p className={classes.Text}>
        ARE YOU A CREATIVE MAGICIAN?
        <br /> WE’VE BEEN LOOKING FOR YOU!
      </p>
      <p className={classes.Text}>
        We are looking for an After Effects master who can leverage their
        artistic and creative design capabilities to create animated and live
        action videos to impress. When others shy away from a complex project,
        you get energized and rise to the challenge.
      </p>
      <p className={classes.Text}>
        Bring your unique approach to crafting motion graphics and animations to
        leave us speechless with the quality and smoothness of your work.
        Ideally, you also have an interest in shooting and editing video, but we
        know you probably aren’t perfect. Do you often look at videos and think
        “I can do better”? Then you should get in touch.
      </p>
    </div>
  );
};

const createItem2 = collapseState => {
  return (
    <div className={classes.Content}>
      <p className={classes.Text}>
        Create visually stunning projects from start to finish, ranging from
        vector-based animations to chromakey compositing and more. Visualize and
        conceptualize animations and other visuals for custom projects. Work
        with input from the team while bringing your own skillset and creative
        insights to the table. Provide thoughtful creative thinking to any
        project you work on. Work together in a team of like-minded individuals
        within an extremely innovating company.
      </p>
    </div>
  );
};

const createItem3 = collapseState => {
  return (
    <div className={classes.Content}>
      <p className={classes.Text}>
        You are extremely creative. You are someone who is highly organized and
        process-oriented. You have a deep appreciation for well-crafted color
        palettes and delightful aesthetics. You are a perfectionist when it
        comes to framing, composition, and timing.
      </p>
    </div>
  );
};

const createItem4 = collapseState => {
  return (
    <div className={classes.Content}>
      <p className={classes.Text}>
        Expert level (more than 5 years of production experience) of the
        following programs – but not limited to: After Effects, Premiere,
        Illustrator and Photoshop. A stunning portfolio that demonstrates your
        ability to craft delightful graphics. Good enough English proficiency to
        be able to interact with colleagues on a daily basis. Collaborative
        spirit and desire for constant improvement.
      </p>
    </div>
  );
};

const createItem5 = collapseState => {
  return (
    <div className={classes.Content}>
      <p className={classes.Text}>
        Work in a fast-paced innovative and high-tech work environment with
        passionate people who love doing what they do. Industry-standard salary
        that grows with your abilities. A bonus system based on your
        performance: the better you do, the more you earn. Attractive secondary
        package (health insurance, fruit at work, pension fund subsidy). 28 paid
        holiday days. Free lunch.
      </p>
    </div>
  );
};
